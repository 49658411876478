import './css/App.css';
import React, { useState } from 'react';  
import { BrowserRouter as Router, Route, Link, Routes, useLocation, useNavigate } from 'react-router-dom'; 
import Header from './Header';
import Register from './Register';
import Login from './Login';
import UploadAndSearch from './UploadAndSearch';
import Profile from './Profile'
import CustomBot from './CustomBot';
import SubscriptionPage from './SubscriptionPage'; // make sure the path is correct
import SixBoxesPage from './Models';
import ContactUs from './Contactus';
import MobileUpload from './MobileUpload'
import PrivacyPolicy from './PrivacyPolicy';
import SamplePage from './SamplePage';
import SampleMobileUpload from './SampleMobileUpload';


function Content({ currentUser, setCurrentUser }) {
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    // If there is no current user, redirect to the sample page
    if (!currentUser && location.pathname === '/') {
      navigate('/samplepage');
    }
  }, [currentUser, location.pathname, navigate]);

  return (
    <div className="App">
      {!['/login', '/signup'].includes(location.pathname) && (
        <Header currentUser={currentUser} setCurrentUser={setCurrentUser} />


      )}
      <div className="App-header">
        <Routes>
          <Route path="/" element={<UploadAndSearch currentUser={currentUser} />} />
          <Route path="/profile/:userId" element={<Profile />} />
          <Route path="/samplepage" element={<SamplePage currentUser={"dc8ad700-4737-4386-a9cb-1cc12591ad34"} />} />
          <Route path="/signup" element={<Register currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
          <Route path="/login" element={<Login currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
          <Route path="/subscription" element={<SubscriptionPage currentUser={currentUser} />} />
          <Route path="/custommodels" element={<SixBoxesPage currentUser={currentUser} />} />
          <Route path="/mobileupload" element={<MobileUpload currentUser={currentUser} />} />
          <Route path="/SampleMobileupload" element={<SampleMobileUpload currentUser={"dc8ad700-4737-4386-a9cb-1cc12591ad34"} />} />
          <Route path="/contactus" element={<ContactUs currentUser={currentUser} />}  />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}  />
          <Route path="/huberbot" element={<CustomBot currentUser={"ecd02121-31d2-4b6b-9fac-f52493dad9f6"} searchTitle={"Ask Andrew Huberman"} filesTitle={"Huberman Lab Episodes"} />}  />
          <Route path="/allin" element={<CustomBot currentUser={"9e368420-15e2-46af-be7e-9447a98b27e5"} searchTitle={"Ask the All In Podcast"} filesTitle={"All In Podcast Episodes"} />}  />
          <Route path="/drive" element={<CustomBot currentUser={"c886bba6-591b-45c8-9ee2-abd930131841"} searchTitle={"Ask Peter Attia"} filesTitle={"Drive Episodes"} />}  />
          <Route path="/medicalmedium" element={<CustomBot currentUser={"f8168a67-f1ce-4186-996c-59a4aca6fc2d"} searchTitle={"Ask Anthony William"} filesTitle={"Medical Medium"} />}  />
        </Routes>
      </div>
    </div>
  );
}

function App() {
  const [currentUser, setCurrentUser] = useState(localStorage.getItem('currentUser') || null);

  return (
    <Router>
      <Content currentUser={currentUser} setCurrentUser={setCurrentUser} />
    </Router>
  );
}

export default App;
