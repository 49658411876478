import React from 'react';
import './css/App.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container" style={{ marginTop: '5%' }}>
      <h1>Privacy Policy</h1>
      <iframe 
          src="/privacy-policy.pdf" 
          width="80%" 
          height="70%" 
          title="Privacy Policy"
          style={{border: "none"}} 
      />
    </div>
  );
};

export default PrivacyPolicy;
