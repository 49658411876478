import './css/App.css';
import './css/UploadAndSearch.css';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Article from '@mui/icons-material/Article';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Backdrop from '@mui/material/Backdrop';
import { BorderColor } from '@material-ui/icons';
import { API_URL } from './constants';
import { handleUpload } from './UploadUtil';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';


const MAX_FILE_SIZE = 10 * 1024 * 1024 * 1024; // 10GB in bytes

function UploadAndSearch({ currentUser }) {
    const isLoggedIn = Boolean(currentUser);
    const navigate = useNavigate();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [isUploadLoading, setIsUploadLoading] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null); // New state to hold the PDF URL
    const [fileName, setFileName] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [showPdf, setShowPdf] = useState(false); // New state to toggle PDF iFrame visibility
    const [searchQuery, setSearchQuery] = useState('');
    const [folderName, setFolderName] = useState('default');
    const [searchResults, setSearchResults] = useState({
        query: "",
        answer: "",
        sourceDocument: []
    });
    const [pastSearchResults, setPastSearchResults] = useState([]);

    const [subfolders, setSubfolders] = useState([]);

    const fetchSubfolders = async () => {
        try {
            const response = await fetch(`${API_URL}/get_subfolders?customerId=${currentUser}&currentFolder=${currentUser}/`);
            const data = await response.json();
            if (data.subFolders) {
                setSubfolders(data.subFolders);
            }
        } catch (error) {
            console.error('Error fetching subfolders:', error);
        }
    };

    
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const openPdf = async (fileName) => {
        setIsDeleteLoading(true); // Use the existing loading state
    
        try {
            const userId = currentUser; // Assuming currentUser holds the user ID
            const response = await fetch(`${API_URL}/stream_pdf?customerId=${userId}&file_name=${fileName}`);
            
            if (response.status === 500) {
                alert('Please re-upload your file to see a PDF preview'); // or handle with a dialog
                setIsDeleteLoading(false); // Reset the loading state
                return;
            }
    
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            setPdfUrl(url);
            setShowPdf(true);
            setFileName(fileName); // Set the fileName state here
        } catch (error) {
            console.error('An error occurred while fetching the PDF:', error);
        } finally {
            setIsDeleteLoading(false); // Reset the loading state
        }
    };
    
    

    const [uploadedFilesSearch, setUploadedFilesSearch] = useState('');

    const handleSubscribe = () => {
        const userId = currentUser;  // Assuming currentUser holds the user ID
        const stripeUrl = `https://buy.stripe.com/9AQ4iH0KFfuAbuw001?client_reference_id=${userId}`;
        window.location.href = stripeUrl;
    };

    const filteredUploadedFiles = uploadedFiles.filter(
        fileName => fileName.toLowerCase().includes(uploadedFilesSearch.toLowerCase())
      );

      const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            const files = Array.from(e.dataTransfer.items).map(item => item.getAsFile());
            files.forEach(file => {
                if (file.size > MAX_FILE_SIZE) {
                    setErrorMessage("Each file must be smaller than 10GB!");
                    return;
                }
                const folderPath = `${currentUser}/${folderName}`; // Construct the folder path
                setSelectedFile(file);
                handleUpload(file, currentUser, setIsUploadLoading, setSelectedFile, fileInputRef, refreshFileList, setErrorMessage, folderPath);
            });
        }
    };
    
    const handleSelectChange = (e) => {
        if (e.target.value === 'create-new-folder') {
            const newFolderName = prompt("Enter the name of the new folder:");
            if (newFolderName) {
                // Additional validation can be added here
                setSubfolders([...subfolders, newFolderName]); // Add the new folder to the subfolders list
                setFolderName(newFolderName);
                refreshFileList(); // Update file list with new folder
                // Additional logic to create the folder in your backend or storage (if necessary)
            }
        } else {
            setErrorMessage(null); 
            setFolderName(e.target.value);
            refreshFileList();
        }
    };
    
    

    const spotifyGreenTheme = createTheme({
        palette: {
            primary: {
                main: '#42d760', // spotify-green
            },
        },
    });

    const sortedUploadedFiles = uploadedFiles.sort((a, b) => a.localeCompare(b))

    useEffect(() => { 
        if (isLoggedIn) {
            document.body.style.overflow = 'hidden';
            return () => {
                document.body.style.overflow = 'auto'; 
            };
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (currentUser) {
            fetchSubfolders();
        }
    }, [currentUser]);
    
    useEffect(() => {
        if (currentUser) {
            refreshFileList();
        }
    }, [currentUser, folderName]);
    

    useEffect(() => {
        // If the user is not logged in, redirect to login page
        if (!isLoggedIn) {
            navigate('/login');
        }
    }, [currentUser, navigate]);

    useEffect(() => {
        if (currentUser) {
            const folderPath = folderName ? `${currentUser}/${folderName}` : currentUser; // Handle root folder case
            fetch(`${API_URL}/get_uploaded_files_by_customerId?customerId=${currentUser}&folder=${folderPath}`)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        throw new Error(data.message);
                    });
                }
                return response.json();
            })
            .then(data => {
                if (data.fileNames && data.fileNames.length > 0) {
                    setUploadedFiles(data.fileNames);
                } else {
                    setErrorMessage("This folder is empty.");
                }
            })
            .catch(error => {
                setErrorMessage(error.message);
                console.error('Error fetching uploaded files:', error);
            });
        }
    }, [currentUser, folderName]); // Add folderName as a dependency
    

    const refreshFileList = () => {
        const folderPath = `${currentUser}/${folderName}`; // Update folder path
        fetch(`${API_URL}/get_uploaded_files_by_customerId?customerId=${currentUser}&folder=${folderPath}`)
        .then(response => {
            if (!response.ok) {
                return response.json().then(data => {
                    throw new Error(data.message);
                });
            }
            return response.json();
        })
        .then(data => {
            if (data.fileNames && data.fileNames.length > 0) {
                setUploadedFiles(data.fileNames);
            } else {
                setErrorMessage();
            }
        })
        .catch(error => {
            setErrorMessage(error.message);
            console.error('Error fetching uploaded files:', error);
        });
    }

    const deleteFile = (fileName) => {
        const folderPath = `${currentUser}/${folderName}`; // Update folder path
        setIsDeleteLoading(true);
        fetch(`${API_URL}/delete_uploaded_file`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customerId: currentUser,
                folder: folderPath, // Use updated folder path
                fileName: fileName + '.pdf'  // Assuming file extension is .pdf
            })
            })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        throw new Error(data.message);
                    });
                }
                return response.json();
            })
            .then(data => {
                console.log(data.message);
                // Remove the deleted file from the state
                setUploadedFiles(prevFiles => prevFiles.filter(file => file !== fileName));
            })
            .catch(error => {
                setErrorMessage(error.message);
                console.error('Error deleting file:', error);
            })
            .finally(() => {
                setIsDeleteLoading(false);
            });;
    }

    const handleSearch = () => {
        setIsSearching(true);
        if (!searchQuery) {
            setIsSearching(false); // Make sure to reset the searching state
            setErrorMessage("Please enter a search query.");
            return;
        }
    
        const folderPath = `${currentUser}/${folderName}`; // Construct the folder path
    
        // Make the fetch request to your Flask API
        fetch(`${API_URL}/search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customerId: currentUser,
                folder: folderPath, // Use the folder path
                searchString: searchQuery
            })
        })
        .then(response => {
            if (response.status === 401) {
                setIsSearching(false); // Reset the searching state for 401 error
                setDialogOpen(true);   // Open the dialog for 401 error
                throw new Error("Not Authorized: You have exceeded the allowed searches as a free user."); // Stop the processing here
            }
            return response.json();
        })
        .then(data => {
            setIsSearching(false);
            if (data.answer) {
                let documents = data.sourceDocument.map(doc => {
                    // Extract the portion after the last `/` character
                    let fileName = doc.split('/').pop();
            
                    // Remove the chunkNum_ and replace `.txt` with `.pdf`
                    fileName = fileName.replace(/^\d+_/, '').replace('.txt', '.pdf');
            
                    // Replace all underscores with spaces
                    fileName = fileName.replace(/_/g, ' ');
            
                    return fileName;
                });
    
                // Filter out duplicates using Set
                documents = [...new Set(documents)];
    
                const newSearchResult = {
                    query: searchQuery,
                    answer: data.answer,
                    sourceDocument: documents
                };
                
                setPastSearchResults(prevResults => [newSearchResult, ...prevResults]);
            } else {
                setErrorMessage("No results found.");
            }
        })
        .catch(error => {
            if (!error.message.includes("Not Authorized")) {
                setIsSearching(false);
                setErrorMessage("Error searching documents: " + error.message);
            }
        });
    };    
    

    return (
        <div className="uploaded-section" style={{ display: 'flex', width: '100vw', paddingTop: '00px', paddingBottom: '40px', marginTop: '65px' }}>
        <div className={isLoggedIn && !isDeleteLoading && !isUploadLoading ? 'upload-and-search-content' : 'upload-and-search-content blur-effect'}>
            
            <Paper elevation={3} className="box-content0" 
            onDragOver={handleDragOver}
            onDrop={handleDrop}>

            {showPdf ? (
                <div style={{ width: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex', // Make this Box a flexbox container
                            alignItems: 'center', // Vertically center-align children
                            marginBottom: '2px', // Add some space below this container
                            justifyContent: 'space-between' // Place space between items
                        }}
                    >
                        {/* Back Button */}
                        <Box
                            sx={{
                                // border: '1px solid', // Add a border to the box
                                width: '33px',
                                height: '33px',
                                borderColor: 'white', // Set border color
                                marginBottom: '10px',
                                borderRadius: 1, // Add some rounding to the corners
                                '&:hover': {
                                    bgcolor: '#525466', // Lighter color on hover
                                },
                            }}
                        >
                            <IconButton
                                onClick={() => setShowPdf(false)}
                                sx={{
                                    width: '100%', // Make the button fill the box
                                    display: 'flex', // Align children (the icon) in the center
                                    justifyContent: 'center', 
                                    alignItems: 'center',
                                    height: '100%',
                                    '&:hover': {
                                        bgcolor: 'transparent', // Remove the default hover effect
                                    },
                                }}
                            >
                                <ArrowBackRoundedIcon
                                    sx={{
                                        color: 'white', // Icon color
                                    }}
                                />
                            </IconButton>
                        </Box>
                
                        {/* Filename */}
                        <Box
                            sx={{
                                flexGrow: 1, // Allow this box to grow and take available space
                            }}
                        >
                            <Typography variant="h4" component="h4" style={{ fontWeight: 'bold', color: 'white', marginBottom: '7px', fontSize: '24px'}}>
                                {fileName.length > 31 ? `${fileName.substring(0, 32).replace(/_/g, ' ')}...` : fileName.replace(/_/g, ' ')}
                            </Typography>
                        </Box>
                
                        {/* Empty box to keep space symmetrical */}
                        <Box sx={{ width: '33px', height: '33px' }} />
                    </Box>
                
                    <iframe
                        src={pdfUrl}
                        style={{ width: '100%', height: 'calc(100vh - 200px)' }} // Adjust the 100px based on your needs
                        frameborder="0"
                        allowfullscreen
                    ></iframe>
                </div>
            
            ) : (
                null
            )}
            {!showPdf && (
                <>
                    <Typography variant="h4" component="h2" style={{ fontWeight: 'bold', color: 'white', padding: '20px'}}>
                        Uploaded Files
                    </Typography>

                    {/* Add a TextField here for searching uploaded files */}
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        
                        <TextField 
                            hiddenLabel={false}
                            className="searchfiles-font-input"
                            placeholder="Search"
                            variant="filled"
                            style={{ flexBasis: '78%' }} // Adjust width to 75%
                            value={uploadedFilesSearch} 
                            onChange={e => setUploadedFilesSearch(e.target.value)}
                        />

                        <FormControl 
                            style={{ flexBasis: '20%', marginLeft: '10px' }}
                            className="white-font-input"
                            // sx={{ backgroundColor: '#e1e4f0' }} // Apply custom background color
                        >
                            <InputLabel className="inputLabel">Folder</InputLabel>
                            <Select
                                value={folderName}
                                label="Folder"
                                onChange={handleSelectChange}
                                style={{ color: 'white', borderColor: 'white', background:'#404150' }}
                            >
                                {subfolders.map((folder, index) => (
                                    <MenuItem key={index} value={folder}>{folder}</MenuItem>
                                ))}
                                <MenuItem value="create-new-folder">
                                    {/* <CreateNewFolderIcon style={{ marginRight: '10px' }} /> */}
                                    📂 New Folder
                                </MenuItem>
                            </Select>
                        </FormControl>



                    </div>



                

                    <div className="uploaded-files-section custom-scrollbar" style={{ flex: 1, overflowY: 'auto', maxHeight: 'calc(100vh - 50px)' }}>


                    {
                        errorMessage ? (
                            <p style={{ color: 'white', fontSize: '18px', justifyContent: 'center' }}>
                                {errorMessage}
                            </p>
                        ) : (
                            sortedUploadedFiles.length > 0 ? (
                                <ul className="uploaded-files-list">
                                    {sortedUploadedFiles.filter(fileName => fileName.toLowerCase().includes(uploadedFilesSearch.toLowerCase())).map(fileName => (
                                        <li key={fileName} className="uploaded-file-item hover-effect" style={{ paddingLeft: '10px', marginRight: '10px' }}>
                                            <div className="file-info">
                                                <Article style={{ marginRight: '8px', color: 'white' }} />
                                                <Typography variant="h6" className="break-long-words hover-text">
                                                    <span onClick={() => openPdf(fileName)} style={{ cursor: 'pointer' }}>
                                                        {fileName.length > 45 ? `${fileName.substring(0, 45).replace(/_/g, ' ')}...` : fileName.replace(/_/g, ' ')}
                                                    </span>
                                                </Typography>
                                            </div>
                                            <IconButton
                                                className="deleteRoundedIcon hover-effect"
                                                onClick={() => deleteFile(fileName)}
                                            >
                                                <DeleteOutlineRoundedIcon className="hover-icon"/>
                                            </IconButton>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <Typography variant="subtitle1" style={{ margin: '20px', color: 'white' }}>
                                    This folder is empty.
                                </Typography>
                            )
                        )
                    }





                    <div className="upload-section" style={{ marginTop: 'auto', marginBottom: '0px' }}>

                        <Button 
                            variant="outlined" 
                            color="success"
                            className="text-spotify-green-button"
                            component="label" // Make the button act as a label
                            style={{ color: '#42d760', borderRadius: '22px', padding: '18px', fontSize: '13px', height: '40px'}} // Spotify green
                        >
                            Upload File
                            
                            <input 
                                type="file" 
                                ref={fileInputRef} 
                                accept=".pdf" 
                                hidden // Hide the actual input
                                multiple
                                onChange={(e) => {
                                    const files = Array.from(e.target.files);
                                    files.forEach(file => {
                                        if (file.size > MAX_FILE_SIZE) {
                                            setErrorMessage("Each file must be smaller than 10GB!");
                                            return;
                                        }
                                        const folderPath = `${currentUser}/${folderName}`; // Construct the folder path
                                        handleUpload(file, currentUser, setIsUploadLoading, setSelectedFile, fileInputRef, refreshFileList, setErrorMessage, folderPath);
                                    });
                                }}
                            />

                            
                        </Button>

                            {selectedFile && <span style={{ marginLeft: '10px', marginTop: '10px', fontSize: '20px' }}>{selectedFile.name}</span>}
                            <br />

                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Typography variant="caption" component="p" style={{ margin: '0', fontSize: '13px'}}>
                            File Count: {uploadedFiles.length} 
                        </Typography>
                        <Typography variant="caption" component="p" style={{ margin: '0', fontSize: '13px'}}>
                            Drag and Drop PDFs
                        </Typography>
                </div>
            </>
            )}
                </Paper>

                <ThemeProvider theme={spotifyGreenTheme}>
                    <Paper elevation={3} className="box-content1">
                    <FormControl 
                            style={{ width: 'auto', marginLeft: '10px', marginBottom: '6px' }}
                            className="mobile-only"
                            // sx={{ backgroundColor: '#e1e4f0' }} // Apply custom background color
                        >
                            <InputLabel className="inputLabel">Folder</InputLabel>
                            <Select
                                value={folderName}
                                label="Folder"
                                onChange={handleSelectChange}
                                style={{ color: 'white', borderColor: 'white', background:'#404150' }}
                            >
                                {subfolders.map((folder, index) => (
                                    <MenuItem key={index} value={folder}>{folder}</MenuItem>
                                ))}
                                <MenuItem value="create-new-folder">
                                    {/* <CreateNewFolderIcon style={{ marginRight: '10px' }} /> */}
                                    📂 New Folder
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <div className="padded">
                            <Typography variant="h4" component="h2" style={{ fontWeight: 'bold', color: 'white', marginBottom: '20px'}}>
                                Search Documents
                            </Typography>

                        


                            <div className="custom-scrollbar" style={{ overflowY: 'auto', marginBottom: '20px' }}>
                                {pastSearchResults.map((result, index) => (
                                    <Paper key={index} elevation={5} style={{ padding: '20px', marginBottom: '20px', backgroundColor: "#343640", color: "white", borderRadius: "15px" }}>
                                        <List>
                                            <ListItem>
                                                <ListItemText primary={result.query} secondary="Search Query" 
                                                secondaryTypographyProps={{ style: { color: 'white' } }}/>
                                            </ListItem>

                                            <ListItem>
                                                <ListItemText primary={result.answer} />
                                            </ListItem>
                                            
                                            {result.sourceDocument.map((doc, idx) => (
                                                <ListItem key={idx}>
                                                    <ListItemText 
                                                        primary={doc} 
                                                        secondary="Source Document" 
                                                        secondaryTypographyProps={{ style: { color: 'white' } }}
                                                        className="break-long-words"
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Paper>
                                ))}
                            </div>
        
                            <div style={{ marginBottom: '30px'}}>
                            <TextField
                                className="white-font-input"
                                fullWidth
                                variant="outlined"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                placeholder="Type your search query..."
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault(); // Prevent the default action (new line) on Enter key press
                                        handleSearch();
                                        setSearchQuery('');  // Clear the text field when Enter is pressed
                                    }
                                }}
                                InputProps={{
                                    style: { color: 'white' }
                                }}
                                multiline // Enable multiline
                                maxRows={6} // Number of visible rows
                            />

                                <Button 
                                    variant="contained" 
                                    className="spotify-green-button" 
                                    onClick={() => {
                                        handleSearch();
                                        setSearchQuery('');  // Clear the text field when button is clicked
                                    }} 
                                    disabled={!searchQuery}
                                    style={{ marginTop: '20px', width: 'auto', height: '40px',borderRadius: '22px', padding: '18px', fontSize: '13px'}}
                                >
                                    Search
                                </Button>

                                <div style={{marginTop: '20px'}}>
                                    {isSearching && <LinearProgress color="primary" />}
                                </div>
                            </div>
                        </div>
                    </Paper>
                </ThemeProvider>
            </div>

            {isDeleteLoading && (

            <Backdrop open={true} style={{ zIndex: 1, color: '#fff' }}>
                <CircularProgress color = 'success' style={{ color: 'white' }} />
            </Backdrop>

            )}

            {isUploadLoading && (
                <Dialog
                    open={true}
                    onClose={() => { /* Handle close if needed */ }}
                    PaperProps={{ className: "custom-dialog" }}
                >    
                    <div>
                        <DialogTitle>
                            <p>Training Docworm on your files.<br />
                            This can take a few minutes for large files.<br />
                            Do NOT refresh the page.
                            </p>
                        </DialogTitle>
                        <CircularProgress color = 'success' style={{ color: 'white' }} />
                    </div>
                </Dialog>                    
            )}

            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                PaperProps={{ className: "custom-dialog2" }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle 
                    id="alert-dialog-title" 
                    style={{ fontWeight: 'bold', fontSize: '24px' }}
                >
                    {"Test Limit Reached"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ color: 'white' }}>
                        Your account has exceeded the Free-Tier limit. 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description" style={{ color: 'white' }}>
                        Start your free-trial to get unlimited searches.
                    </DialogContentText>
                    <p style={{ fontSize: '14px', color: 'white' }}>
                        <Link to="/subscription">
                            <Button variant="text" className="text-spotify-green-button2" style={{ fontSize: '14px'}}>
                            LEARN MORE
                            </Button>
                        </Link>
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} className="text-spotify-green-button1" autoFocus>
                        Dismiss
                    </Button>
                    <Button onClick={handleSubscribe} className="spotify-green-button" autoFocus style={{ marginLeft: '15px' }}>
                        Free-Trial
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default UploadAndSearch;
