import React from 'react';
import './css/App.css';

const ContactUs = () => {
  return (
    <div className="contact-us">
      <h1>Contact Us</h1>
      <p>Have questions or need assistance? Reach out to us directly.</p>
      
      <div className="contact-info">
        <p><strong>Email:</strong> <a href="mailto:info@docworm.ai">info@docworm.ai</a></p>
      </div>
      
      <div className="privacy-link">
        <p>By using this form, you agree with the storage and handling of your data by this website.</p>
        <p>Read our <a href="/privacy-policy">Privacy Policy</a> for more information.</p>
        
        {/* Embed the PDF */}
        
      </div>
    </div>
  );
};

export default ContactUs;
