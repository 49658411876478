import * as pdfjsLib from 'pdfjs-dist/webpack';
import { API_URL } from './constants';

pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';

export const convertPdfToText = (pdfFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(pdfFile);
      reader.onload = async function() {
        const pdfData = new Uint8Array(reader.result);
        
        try {
          const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
          let textContent = '';
  
          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const content = await page.getTextContent();
            const text = content.items.map(item => item.str).join(' ');
            textContent += text + ' ';
          }
  
          resolve(textContent);
        } catch (error) {
          console.error("Error in reading PDF:", error);
          reject(error);
        }
      };
  
      reader.onerror = function(error) {
        console.error("Error in FileReader:", error);
        reject(error);
      };
    });
};
  

export const handleUpload = async (selectedFile, currentUser, setIsUploadLoading, setSelectedFile, fileInputRef, refreshFileList, setErrorMessage, folderPath) => {
  if (!selectedFile) return;

  setIsUploadLoading(true);
  setErrorMessage(null);

  try {
      const originalFileName = selectedFile.name.split('.').slice(0, -1).join('.');

      // Step 1: Upload the original PDF file
      const formDataPdf = new FormData();
      formDataPdf.append('file', selectedFile);
      formDataPdf.append('customerId', currentUser);
      formDataPdf.append('folder', folderPath); // Include folder path

      await fetch(`${API_URL}/upload_pdf`, {
          method: 'POST',
          body: formDataPdf
      });

      // Step 2: Convert PDF to Text
      const pdfText = await convertPdfToText(selectedFile);

      // Step 3: Chunk the Text
      const chunkSize = 20 * 1024;  // 20KB
      let start = 0;
      let end = chunkSize;
      let chunkNum = 1;
      const uploadPromises = [];

      while (start < pdfText.length) {
          const chunkText = pdfText.slice(start, end);

          const formDataText = new FormData();
          formDataText.append('file', new Blob([chunkText], { type: 'text/plain' }), `${originalFileName}.txt`);
          formDataText.append('customerId', currentUser);
          formDataText.append('folder', folderPath); // Include folder path
          formDataText.append('chunkNum', chunkNum.toString());

          // Add fetch Promise to the array
          uploadPromises.push(
              fetch(`${API_URL}/upload_chunk_file`, {
                  method: 'POST',
                  body: formDataText
              })
          );

          // If 5 promises are collected, wait for them to finish
          if (uploadPromises.length === 1) {
              await Promise.all(uploadPromises);
              uploadPromises.length = 0; // Clear the array
          }

          // Update the start and end indices for the next chunk
          start = end;
          end = start + chunkSize;
          chunkNum++;
      }

      // Wait for any remaining promises to finish
      if (uploadPromises.length > 0) {
          await Promise.all(uploadPromises);
      }

      setSelectedFile(null); // Reset the file input
      fileInputRef.current.value = "";  // Reset the file input in the UI
      refreshFileList();
  } catch (error) {
      setErrorMessage(error.message);
      console.error('Error uploading file:', error);
  } finally {
      setIsUploadLoading(false);
  }
};

