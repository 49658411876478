import React from 'react';
import { Grid, Paper, Button, Typography, Box } from '@mui/material';
import './css/App.css';
import './css/Models.css';
import { useNavigate } from 'react-router-dom';

const SixBoxesPage = () => {
    const navigate = useNavigate();  // Initialize useNavigate
  
    const handleButtonClick = (boxNumber) => {
      if (boxNumber === 1) {
        navigate('/huberbot');  // Redirect to /huberbot
      }
      else if (boxNumber === 2) {
        navigate('/allin');  // Redirect to /huberbot
      }
      else if (boxNumber === 3) {
        navigate('/drive');  // Redirect to /huberbot
      }
      else if (boxNumber === 4) {
        navigate('/medicalmedium');  // Redirect to /huberbot
      }
      // You can add more conditions here for other boxes if needed
    };

return (
    <div className="contentContainer" style={{maxWidth: '800px'}} >
        <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Grid container spacing={3} justifyContent="center">
                {/* Row for "Featured Custom Models" */}
                <Grid container item xs={12} justifyContent="center">
                    <Typography variant="h4" component="h2" style={{ fontWeight: 'bold', color: '#e0e0e0', padding: '20px', textAlign: 'center' }}>
                        Featured Custom Models
                    </Typography>
                </Grid>

                {/* Consolidated Row */}
                <Grid container item spacing={3} justifyContent="center">
                    {[1, 2, 3, 4, 5, 6].map((boxNumber) => (
                        <Grid item key={boxNumber} style={{ display: 'flex', justifyContent: 'center', marginBottom: '0px' }}>
                            <Paper elevation={3} style={{ backgroundColor: '#444655', height: '240px', width: '220px', borderRadius:'10px', border: '1px solid #42d760', }}>
                                <Box p={3} display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                                    <Typography variant="h5" style={{ fontWeight: 'bold', color: '#e0e0e0'}}>
                                        {boxNumber === 1 ? "🔬 Huberbot" : 
                                        (boxNumber === 2 ? "🎲 All In Podcast" : 
                                        (boxNumber === 3 ? "🥼 Drive Podcast" : 
                                        (boxNumber === 4 ? "🥬 Medical Medium" :
                                        (boxNumber === 5 ? "📚 Jordan Peterson" :
                                        (boxNumber === 6 ? "📱 Lex Fridman" :
                                        `Box ${boxNumber}`)))))}
                                    </Typography>
                                    <Typography variant="body1" style={{ color: '#e0e0e0'}}>
                                        {boxNumber === 1 ? "Trained on episodes of the Huberman Lab Podcast 🧪" : 
                                        (boxNumber === 2 ? "Trained on recent episodes of the All In Podcast ♠️" :
                                        (boxNumber === 3 ? "Trained on recent episodes of Peter Attia's Drive Podcast 🩺" :
                                        (boxNumber === 4 ? "Trained on the works of Anthony William - Medical Medium 🥦" : 
                                        (boxNumber === 5 ? "Trained Jordan Peterson's public works" :
                                        (boxNumber === 6 ? "Trained on recent episodes of The Lex Fridman Podcast" :
                                        `Some text for Box ${boxNumber}`)))))}
                                    </Typography>
                                    <div style={{ textAlign: 'center' }}>
                                        <Button variant="contained" color="primary" className="spotify-green-button" style={{ marginBottom: '40px', width:'auto', color: 'black', height: '40px', borderRadius: '22px', padding: '15px', fontSize: '13px'}} onClick={() => handleButtonClick(boxNumber)}>
                                            {boxNumber <= 4 ? "query" : "Coming soon"}
                                        </Button>
                                    </div>
                                </Box>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </div>
    </div>
);
};

export default SixBoxesPage;