import './css/App.css';
import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import jwt_decode from 'jwt-decode';
import { API_URL } from './constants';
import Divider from '@mui/material/Divider';

function Login({ currentUser, setCurrentUser }) {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        // If the user is already logged in, redirect to main page
        if (currentUser) {
            navigate('/');
        }
    }, [currentUser, navigate]);

    useEffect(() => {
        // Initialize Google Sign-In after component mounts
        const script = document.createElement('script');
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    
        script.onload = () => {
            window.google.accounts.id.initialize({
                client_id: "1095521865453-dpa2r787eaqnsn5umkcbu1rgoomh0old.apps.googleusercontent.com",
                callback: handleGoogleLogin
            });
            window.google.accounts.id.renderButton(
                document.getElementById("googleButtonDiv"),
                { theme: "outline", size: "large" }
            );
        };
    }, []);
    
    const handleGoogleLogin = async (response) => {
        console.log("Encoded JWT ID token: ", response.credential);
        const userObject = jwt_decode(response.credential);
        console.log(userObject);
    
        try {
            const res = await fetch(`${API_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    googleToken: response.credential
                })
            });
            
            const data = await res.json();
            
            if (res.status === 401) {  // assuming 401 is the status returned for unauthorized
                setErrorMessage("You do not have a DocWorm account connected to your Google Account. If you have a DocWorm account, please try log in with your DocWorm email or username. If you do not have a DocWorm account, please sign up.");
            } else if (res.status === 400) {
                setErrorMessage(data.message);
            } else {
                console.log(data);
                setCurrentUser(data.customerId);
                localStorage.setItem('currentUser', data.customerId);
                navigate('/');
            }
        } catch (error) {
            console.error('Google Sign In failed:', error);
            setErrorMessage('Google Sign In failed. Please try again.');
        }
    };
    

    const loginUser = () => {
        fetch(`${API_URL}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        })
        .then(response => {
            if (response.status === 401) {
                return response.json().then(data => {
                    setErrorMessage(data.message);
                });
            } else {
                return response.json().then(data => {
                    console.log(data);
                    setCurrentUser(data.customerId);
                    localStorage.setItem('currentUser', data.customerId);
                    navigate('/');
                });
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setErrorMessage('There was an issue logging in!');
        });
    }

    const handleResponse = (response) => {
        // Add your response handling logic here
    };

    const handleError = (error) => {
        console.error('Error:', error);
        setErrorMessage('There was an issue logging in!');
    };


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ 
                background: 'linear-gradient(to bottom, black,  #202123)', 
                padding: '40px', 
                display: 'flex', 
                flexDirection: 'column', 
                gap: '20px', 
                alignItems: "center", //horizonlly center items
                justifyContent: "center", // This will vertically center the items
                position: 'relative',
                top: '0px', //Moving items south of center
                width: '100vw',  // adjusted width
                height: '90vh' // adjusted height
            }}> 
                <img src={process.env.PUBLIC_URL + '/DocWorm-c1-logo-png3.png'} alt="DocWorm Logo" style={{ height: '80px', marginRight: '10px' }} />

                <Typography variant="h4" component="h2" style={{ fontWeight: 'bold', color: 'white', padding: '20px'}}>
                    Login to DocWorm
                </Typography>

                {errorMessage &&
                    <div style={{maxWidth: "300px", wordWrap: "break-word", marginBottom: "10px", color: 'white'}}>
                        <p style={{ fontSize: '14px' }}>{errorMessage}</p> {/* Set font size here */}
                    </div>
                }

                <div style={{maxWidth: "300px"}}>
                
                    <TextField
                        className="white-font-input"
                        type="email"
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        style={{ marginBottom: '16px' }} // Added space between fields
                    />
                    <TextField
                        className="white-font-input"
                        type="password"
                        label="Password"
                        variant="outlined"
                        fullWidth
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        style={{ marginBottom: '16px' }} // Added space between fields
                    />
                    <Button variant="contained" onClick={loginUser} className="spotify-green-button" style={{ width: 'auto', height: '40px',borderRadius: '22px', padding: '18px', fontSize: '13px'}}>
                        Login
                    </Button>
                    <Divider style={{ margin: '20px 0', backgroundColor: '#ffffff' }} />
                    <div id="googleButtonDiv" 
                    style={{
                    marginTop: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                    }}>
                    </div>
                    
                </div>
                <p style={{ fontSize: '14px', color: 'white' }}>
                    Don't have an account? 
                    <Link to="/signup">
                        <Button variant="text" className="text-spotify-green-button">
                            Sign Up for Docworm
                        </Button>
                    </Link>
                </p>
            </div>
        </div>
    );
}

export default Login;
