import './css/Subscription.css';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SmartToyIcon from '@mui/icons-material/SmartToy';


function SubscriptionPage({ currentUser }) {
    const navigate = useNavigate();
    const isLoggedIn = Boolean(currentUser);

    useEffect(() => {
        // If the user is not logged in, redirect to login page
        if (!isLoggedIn) {
            navigate('/login');
            return;
        }
    }, [currentUser, navigate]);

    const handleSubscribe = () => {
        const userId = currentUser;  // Assuming currentUser holds the user ID
        const stripeUrl = `https://buy.stripe.com/9AQ4iH0KFfuAbuw001?client_reference_id=${userId}`;
        window.location.href = stripeUrl;
    };
    
    return (
        <div style={{ 
            padding: '2%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: window.innerWidth <= 768 ? '0px' : '20px' 
        }}>
            {window.innerWidth > 768 && (
                <Typography 
                    variant="h4"
                    component="h2"
                    gutterBottom
                    style={{ 
                        fontWeight: 'bold',
                        color: '#e0e0e0',
                        textAlign: 'center',
                        margin: '50px 0 40px 0'
                    }}
                >
                    Subscription Plans
                </Typography>
            )}

            <Paper style={{ 
                width: '90%',
                maxWidth: '800px', // Adjusting max-width for desktop
                padding: '2%',
                borderRadius: '25px',
                border: '2px solid #42d760',
                backgroundColor: '#444655',
                color: '#e0e0e0',
                boxSizing: 'border-box',
                marginBottom: '2em'
            }}>

            <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                width: '100%',
                padding: '20px 0' // Adjust padding as needed
            }}>
            <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '20px', paddingRight: '20px' }}>
                <EmojiEventsIcon style={{ 
                    marginRight: '10px', 
                    fontSize: window.innerWidth <= 768 ? '24px' : '36px', // smaller font size for mobile screens
                    fill: '#e0e0e0' 
                }} />
                <Typography 
                    variant="h4" 
                    component="h2" 
                    style={{ 
                        fontWeight: 'bold', 
                        color: '#e0e0e0',
                        fontSize: window.innerWidth <= 768 ? '1.5em' : '1.4em', // Adjust font sizes as needed
                    }}
                >
                    Pro-Plan
                </Typography>
            </div>
                            
            <Typography 
                variant="h4" 
                component="h2" 
                style={{ 
                    fontSize: window.innerWidth <= 768 ? '1.2em' : '30px', 
                    color: '#e0e0e0',
                    marginRight: '20px'
                }}
            >
                $4.49/month
            </Typography>

            </div>


                <Typography variant="h6" component="p" style={{ textAlign: 'left', marginBottom: '20px', color: '#e0e0e0', marginLeft: '20px'  }}>
                    
                    <div style={{ fontSize: window.innerWidth <= 768 ? '1.1em' : '1.3em', fontWeight: 'bold', marginTop: '10px', color: '#e0e0e0'}}>
                        Start with a 7 Day Free Trial
                    </div>

                    Unlock all premium features with the Pro-Plan at just $4.49/month.
                    <br/>
                    <div style={{ fontSize: window.innerWidth <= 768 ? '1.2em' : '1.3em', fontWeight: 'bold', marginTop: '20px', color: '#e0e0e0' }}>
                        Unlocks:
                    </div>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <DocumentScannerIcon style={{ fill: '#e0e0e0' }} />
                            </ListItemIcon>
                            10GB per file uploads
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PlagiarismIcon style={{ fill: '#e0e0e0' }} />
                            </ListItemIcon>
                            Unlimited questions per day
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SmartToyIcon style={{ fill: '#e0e0e0' }} />
                            </ListItemIcon>
                            Unlimited access to Custom Models
                        </ListItem>
                    </List>
                </Typography>


                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>  {/* Centering the button */}
                    <Button variant="contained" onClick={handleSubscribe} className="spotify-green-button" style={{ width: 'auto', height: '40px',borderRadius: '22px', padding: '15px', fontSize: '13px'}}>
                        Subscribe Now
                    </Button>
                </div>
            </Paper>
        </div>
    );
}

export default SubscriptionPage;
