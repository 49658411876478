import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import './css/Register.css';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import jwt_decode from "jwt-decode"
import { API_URL } from './constants';
import Divider from '@mui/material/Divider';

function Register({ currentUser, setCurrentUser }) {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [ user, setUser ] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        // If the user is already logged in, redirect to main page
        if (currentUser) {
            navigate('/');
        }
    }, [currentUser, navigate]);

    useEffect(() => {
        // Initialize Google Sign-In after component mounts
        const script = document.createElement('script');
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        script.onload = () => {
            window.google.accounts.id.initialize({
                client_id: "1095521865453-dpa2r787eaqnsn5umkcbu1rgoomh0old.apps.googleusercontent.com",  // Replace with your client ID
                callback: handleCredentialResponse
            });
            window.google.accounts.id.renderButton(
                document.getElementById("buttonDiv"),
                { theme: "outline", size: "large" }
            );
        };
    }, []);

    const handleCredentialResponse = async (response) => {
        console.log("Encoded JWT ID token: ", response.credential);
        const userObject = jwt_decode(response.credential);
        console.log(userObject);
        setUser(userObject);

        try {
            const res = await fetch(`${API_URL}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    googleToken: response.credential
                })
            });
            
            const data = await res.json();
            
            if (res.status === 400 || res.status === 409) {
                setErrorMessage(data.message);
            } else {
                console.log(data);
                setCurrentUser(data.customerId);
                localStorage.setItem('currentUser', data.customerId);
                navigate('/');
            }
        } catch (error) {
            console.error('Google Sign Up failed:', error);
            setErrorMessage('Google Sign Up failed. Please try again.');
        }
    };


    const registerUser = () => {
        if (!password.trim()) { // Check if the password field is empty or just whitespace
            setErrorMessage('Password field cannot be left blank.');
            return;
        }

        // Check if the email is valid
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(email)) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }

        fetch(`${API_URL}/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        })
        .then(response => {
            if (response.status === 409) {
                return response.json().then(data => {
                    setErrorMessage(data.message);
                });
            } else {
                return response.json().then(data => {
                    console.log(data);
                    setCurrentUser(data.customerId);
                    localStorage.setItem('currentUser', data.customerId);
                    navigate('/');
                });
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setErrorMessage('There was an issue signing up!');
        });
    }

    return (
        <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', // Added for vertical centering
            gap: '10px', 
            alignItems: "center",
            backgroundColor: 'white', 
            height: '100vh', // This ensures the white background covers the entire viewport height
            width: '100vw' // This ensures the white background covers the entire viewport width
        }}>

            <img src={process.env.PUBLIC_URL + '/DocWorm-c1-logo-png2.png'} alt="DocWorm Logo" style={{ height: '80px', marginRight: '10px' }} />
            <Typography variant="h4" component="h2" style={{ fontWeight: 'bold', color: 'Black', padding: '30px'}}>
                Sign up for DocWorm
            </Typography>
            
            {errorMessage &&
                <div style={{maxWidth: "300px", wordWrap: "break-word", marginBottom: "10px"}}>
                    <p style={{ color: 'black', fontSize: '14px' }}>{errorMessage}</p>
                </div>
            }
            
            <div style={{maxWidth: "300px"}}>
                <TextField
                    className="black-font-input"
                    type="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    style={{ marginBottom: '16px' }}
                />
                <TextField
                    className="black-font-input"
                    type="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    style={{ marginBottom: '16px' }}
                />
                {/* Replacing native button with Material-UI Button */}
                <Button className='register-green-button' onClick={registerUser} style={{ marginBottom: '25px', width: 'auto', height: '40px',borderRadius: '22px', padding: '18px', fontSize: '13px' }}>
                    Sign Up
                </Button>
                <Divider style={{ backgroundColor: '#000000' }} />
                <div id="buttonDiv" 
                     style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                     }}>
                </div> 
                <div>
                    <Typography style={{ fontSize: '14px', color: 'black', marginTop: '40px' }}>
                        Already have an account? 
                        <Link to="/login">
                            <Button variant="text" className="text-spotify-green-button">
                                Login to Docworm
                            </Button>
                        </Link>
                    </Typography>
                </div>
            </div>
        </div>
    );
}

export default Register;
