import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import './css/Header.css';
import './css/App.css';
import './css/UploadAndSearch.css';

// Material-UI imports
import AppBar from '@mui/material/AppBar';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBoxIcon from '@mui/icons-material/AccountBox';


const pages = ['Custom Models', 'My Docs'];
const mobilePages = ['Search', 'Upload'];
const settings = [ 'Subscription', 'Manage Account', 'Logout'];
const mobileSettings = [ 'Custom Models', 'Subscription', 'Manage Account', 'Contact Us', 'Logout'];

function Header({ currentUser, setCurrentUser }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleLogout = () => {
    setCurrentUser(null);
    localStorage.removeItem('currentUser');
    navigate('/login');
    setAnchorElUser(null);  // Close the menu
  };

  const handlePageClick = (page) => {
    if (page === 'Custom Models') {
      navigate('/custommodels');  // Navigate to Custom Models
    } else if (page === 'My Docs') {
      navigate('/'); 
    } else if (page === 'Search') {
      if (currentUser) {
        navigate('/');  // User is logged in, navigate to MobileUpload
      } else {
        navigate('/samplepage');  // User is not logged in, navigate to Samplemobileupload
      }
    } else if (page === 'Upload') {
      // Check if currentUser is null or not
      if (currentUser) {
        navigate('/MobileUpload');  // User is logged in, navigate to MobileUpload
      } else {
        navigate('/Samplemobileupload');  // User is not logged in, navigate to Samplemobileupload
      }
    }
  
    handleCloseNavMenu();
  };
  
  
  const handleSettingClick = (setting) => {
    if (setting === 'Logout') {
      handleLogout();
    } else if (setting === 'Manage Account') {
      manageAccount();
    } else if (setting === 'Subscription') {
      navigate('/subscription');
    } else if (setting === 'Custom Models') {
      navigate('/custommodels');
    } else if (setting === 'Contact Us') {
      navigate('/contactus'); 
    handleCloseUserMenu();
    }
  };

  const manageAccount = () => {
    window.location.href = 'https://billing.stripe.com/p/login/4gw4hf3B90SC0s8288';
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#343541' }}>
      <Container maxWidth="auto">
        <Toolbar disableGutters sx={{ padding: '0', minHeight: 'auto' }}>
        <img src={process.env.PUBLIC_URL + '/DocWorm-c1-logo-png1.png'} alt="DocWorm Logo" style={{ height: '40px', marginRight: '10px' }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'arial',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            DocWorm
          </Typography>
          
          

          
          {/* Page Navigation for desktop */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, index) => (
              <React.Fragment key={page}>
                <div className="verticalLine"></div>
              <Button
                key={page}
                variant="text"
                size='medium'
                onClick={() => handlePageClick(page)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
              </React.Fragment>
            ))}
          </Box>

              {/* Page Navigation for Mobile */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            {mobilePages.map((mobilePages, index) => (
              <React.Fragment key={mobilePages}>
              <div className="verticalLine"></div>
              <Button
                key={mobilePages}
                variant="text"
                size='medium'
                onClick={() => handlePageClick(mobilePages)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {mobilePages}
              </Button>

              </React.Fragment>
            ))}
          </Box>



          {location.pathname === '/samplepage' && (
            <Button
            variant="outlined" 
            color="success"
            className="text-spotify-green-button"
            component="label" // Make the button act as a label
            style={{ color: '#42d760', marginRight: '15px', borderRadius: '22px', padding: '18px', fontSize: '13px', height: '40px'}} // Spotify green
            sx={{
              '@media (max-width: 768px)': {
                display: 'none',
              },
            }}
              onClick={() => navigate('/login')}
            >
              Log in
            </Button>
          )}

          { (location.pathname === '/samplepage' || location.pathname === '/Samplemobileupload') && (
            <Button
              variant="text"
              size="medium"
              className="spotify-green-button"
              onClick={() => navigate('/signup')}
              sx={{ my: 2, color: '#46c45f'}}
              style={{ marginRight: '15px', borderRadius: '22px', padding: '18px', fontSize: '13px', height: '40px'}}
            >
              Sign Up
            </Button>
          )}



          {/* User Settings for desktop */}
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: location.pathname === '/samplepage' ? 'none' : 'flex'} }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu}>
                <MenuIcon style={{ fill: '#ffffff', fontSize: '42px' }} />
              </IconButton>
            </Tooltip>


            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleSettingClick(setting)}
                >
        
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* User Settings for Mobile */}
          <Box sx={{
            flexGrow: 0,
            display: {
              xs: (location.pathname === '/samplepage' || location.pathname === '/Samplemobileupload') ? 'none' : 'flex',
              md: 'none'
            }
          }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu}>
                <MenuIcon style={{ fill: '#ffffff', fontSize: '42px' }} />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {mobileSettings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleSettingClick(setting)}
                >
        
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
