
import './css/CustomBot.css';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Article from '@mui/icons-material/Article';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Backdrop from '@mui/material/Backdrop';
import { BorderColor } from '@material-ui/icons';
import { API_URL } from './constants';
import { handleUpload } from './UploadUtil';

function CustomBot ({ currentUser, searchTitle, filesTitle }) {
    const isLoggedIn = Boolean(currentUser);
    const navigate = useNavigate();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [isUploadLoading, setIsUploadLoading] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState({
        query: "",
        answer: "",
        sourceDocument: []
    });
    const [pastSearchResults, setPastSearchResults] = useState([]);
    
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            const file = e.dataTransfer.items[0].getAsFile();
            setSelectedFile(file);
        }
    };

    const spotifyGreenTheme = createTheme({
        palette: {
            primary: {
                main: '#42d760', // spotify-green
            },
        },
    });

    useEffect(() => { 
        if (isLoggedIn) {
            document.body.style.overflow = 'hidden';
            return () => {
                document.body.style.overflow = 'auto'; 
            };
        }
    }, [isLoggedIn]);

    useEffect(() => {
        // If the user is not logged in, redirect to login page
        if (!isLoggedIn) {
            navigate('/login');
        }
    }, [currentUser, navigate]);

    useEffect(() => {
        if (currentUser) {
            fetch(`${API_URL}/get_uploaded_files_by_customerId?customerId=${currentUser}&folder=${currentUser}`)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        throw new Error(data.message);
                    });
                }
                return response.json();
            })
            .then(data => {
                if (data.fileNames && data.fileNames.length > 0) {
                    setUploadedFiles(data.fileNames);
                } else {
                    setErrorMessage();
                }
            })
            .catch(error => {
                setErrorMessage(error.message);
                console.error('Error fetching uploaded files:', error);
            });
        }
    }, [currentUser]);

    const refreshFileList = () => {
        fetch(`${API_URL}/get_uploaded_files_by_customerId?customerId=${currentUser}&folder=${currentUser}`)
        .then(response => {
            if (!response.ok) {
                return response.json().then(data => {
                    throw new Error(data.message);
                });
            }
            return response.json();
        })
        .then(data => {
            if (data.fileNames && data.fileNames.length > 0) {
                setUploadedFiles(data.fileNames);
            } else {
                setErrorMessage();
            }
        })
        .catch(error => {
            setErrorMessage(error.message);
            console.error('Error fetching uploaded files:', error);
        });
    }

    const deleteFile = (fileName) => {
        setIsDeleteLoading(true);
        fetch(`${API_URL}/delete_uploaded_file`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customerId: currentUser,
                folder: currentUser,
                fileName: fileName + '.pdf'  // Append .txt to the file name
            })
            })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        throw new Error(data.message);
                    });
                }
                return response.json();
            })
            .then(data => {
                console.log(data.message);
                // Remove the deleted file from the state
                setUploadedFiles(prevFiles => prevFiles.filter(file => file !== fileName));
            })
            .catch(error => {
                setErrorMessage(error.message);
                console.error('Error deleting file:', error);
            })
            .finally(() => {
                setIsDeleteLoading(false);
            });;
    }

    const handleSearch = () => {
        setIsSearching(true);
        if (!searchQuery) {
            setErrorMessage("Please enter a search query.");
            return;
        }
    
        // Make the fetch request to your Flask API
        fetch(`${API_URL}/search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customerId: currentUser,
                folder: currentUser,
                searchString: searchQuery
            })
        })
        .then(response => response.json())
        .then(data => {
            setIsSearching(false);
            if (data.answer) {
                let documents = data.sourceDocument.map(doc => {
                    // Extract the portion after the last `/` character
                    let fileName = doc.split('/').pop();
            
                    // Remove the chunkNum_ and replace `.txt` with `.pdf`
                    fileName = fileName.replace(/^\d+_/, '').replace('.txt', '.pdf');
            
                    return fileName;
                });
            
                // Filter out duplicates using Set
                documents = [...new Set(documents)];
            
                const newSearchResult = {
                    query: searchQuery,
                    answer: data.answer,
                    sourceDocument: documents
                };
                
                setPastSearchResults(prevResults => [newSearchResult, ...prevResults]);
            } else {
                setErrorMessage("No results found.");
            }
        })
        .catch(error => {
            setIsSearching(false);
            setErrorMessage("Error searching documents: " + error.message);
        });
    };    

    return (
        <div className="mainContainer">
            <div className={isLoggedIn && !isDeleteLoading && !isUploadLoading ? 'upload-and-search-content' : 'upload-and-search-content blur-effect'}>
                
                {/* <Paper elevation={3} className="box-content" 
                style={{ display: 'flex', flexDirection: 'column', height: 'auto', maxHeight: 'flex' }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}>
                    <Typography variant="h4" component="h2" style={{ fontWeight: 'bold', color: '#e0e0e0', padding: '20px'}}>
                        {filesTitle}
                    </Typography>

                    
                    <div className="uploaded-files-section custom-scrollbar" style={{ flex: 1, overflowY: 'auto', maxHeight: 'calc(100vh - 50px)' }}>
                        <ul className="uploaded-files-list">
                            {uploadedFiles.map(fileName => (
                                <li key={fileName} className="uploaded-file-item">
                                    <div className="file-info">
                                        <Article style={{ marginRight: '8px', color: '#e0e0e0' }} />
                                        <Typography variant="h6" component="span">
                                            {fileName.length > 45 ? `${fileName.substring(0, 45)}...` : fileName}.pdf
                                        </Typography>

                                    </div>
                                    <IconButton
                                        className="deleteRoundedIcon hover-effect"
                                        onClick={() => deleteFile(fileName)}
                                    >
                                        <DeleteIcon className="default-icon" style={{ color: '#e0e0e0' }}/>
                                        <DeleteForeverRoundedIcon className="hover-icon"/>
                                    </IconButton> 
                                </li>
                            ))}
                        </ul>
                        <div className="upload-section" style={{ marginTop: 'auto', marginBottom: '20px' }}>
                            <Button 
                                variant="outlined" 
                                color="success"
                                className="text-spotify-green-button"
                                component="label" // Make the button act as a label
                                style={{ color: '#42d760', marginBottom: '2px' }} // Spotify green
                            >
                                Choose File
                                
                                <input 
                                    type="file" 
                                    ref={fileInputRef} 
                                    accept=".pdf" 
                                    hidden // Hide the actual input
                                    onChange={(e) => setSelectedFile(e.target.files[0])}
                                />
                                
                            </Button> 
                            {selectedFile && <span style={{ marginLeft: '10px', marginTop: '10px', fontSize: '20px' }}>{selectedFile.name}</span>}
                            <br />
                            <Button 
                                variant="contained" 
                                className="spotify-green-button" 
                                onClick={() => handleUpload(selectedFile, currentUser, setIsUploadLoading, setSelectedFile, fileInputRef, refreshFileList, setErrorMessage)} 
                                disabled={!selectedFile}
                                style={{ width: 'auto', height: '40px', marginTop: '0px' }}  // Explicit size
                            >
                                Upload
                            </Button> 
                        </div>
                    </div>
                </Paper> */}
                
                <ThemeProvider theme={spotifyGreenTheme}>
                    <Paper elevation={3} className="box-content1">
                        <div className="padded">
                            <Typography variant="h4" component="h2" style={{ fontWeight: 'bold', color: '#e0e0e0', marginBottom: '20px'}}>
                                {searchTitle}
                            </Typography>

                            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

                            <div className="custom-scrollbar" style={{ overflowY: 'auto', marginBottom: '20px' }}>
                                {pastSearchResults.map((result, index) => (
                                    <Paper key={index} elevation={5} style={{ padding: '20px', marginBottom: '20px', backgroundColor: "#343640", color: "#e0e0e0", borderRadius: "15px" }}>
                                        <List>
                                            <ListItem>
                                                <ListItemText primary={result.query} secondary="Search Query" 
                                                secondaryTypographyProps={{ style: { color: '#e0e0e0' } }}/>
                                            </ListItem>

                                            <ListItem>
                                                <ListItemText primary={result.answer} />
                                            </ListItem>
                                            
                                            {result.sourceDocument.map((doc, idx) => (
                                                <ListItem key={idx}>
                                                    <ListItemText 
                                                        primary={doc} 
                                                        secondary="Source Document" 
                                                        secondaryTypographyProps={{ style: { color: '#e0e0e0' } }}
                                                        className="break-long-words"
                                                    />

                                                </ListItem>
                                            ))}
                                        </List>
                                    </Paper>
                                ))}
                            </div>
        
                            <div style={{ marginBottom: '30px'}}>
                                <TextField
                                    className="white-font-input"
                                    fullWidth
                                    variant="outlined"
                                    value={searchQuery}
                                    onChange={e => setSearchQuery(e.target.value)}
                                    placeholder="Type your search query..."
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault(); // Prevent the default action (new line) on Enter key press
                                            handleSearch();
                                            setSearchQuery('');  // Clear the text field when Enter is pressed
                                        }
                                    }}
                                    InputProps={{
                                        style: { color: 'white' }
                                    }}
                                    multiline // Enable multiline
                                    maxRows={6} // Number of visible rows
                                />         
                                <Button 
                                    variant="contained" 
                                    className="spotify-green-button" 
                                    onClick={() => {
                                        handleSearch();
                                        setSearchQuery('');  // Clear the text field when button is clicked
                                    }} 
                                    disabled={!searchQuery}
                                    style={{ marginTop: '20px', width: 'auto', height: '40px', borderRadius: '22px', padding: '18px', fontSize: '13px'}}
                                >
                                    Search
                                </Button>

                                <div style={{marginTop: '20px'}}>
                                    {isSearching && <LinearProgress color="primary" />}
                                </div>
                            </div>
                        </div>
                    </Paper>
                </ThemeProvider>
            </div>

            {isDeleteLoading && (

                <Backdrop open={true} style={{ zIndex: 1, color: '#fff' }}>
                    <CircularProgress color="success" />
                </Backdrop>            
            )}

            {isUploadLoading && (
                <Dialog
                    open={true}
                    onClose={() => { /* Handle close if needed */ }}
                    PaperProps={{ className: "custom-dialog" }}
                >    
                    <div>
                        <DialogTitle>
                            <p>Training Docworm on your files.<br />
                            This can take a few minutes for large files.<br />
                            Do NOT refresh the page.
                            </p>
                        </DialogTitle>
                        <CircularProgress color="success" />
                    </div>
                </Dialog>                    
            )}
        </div>
    );
}

export default CustomBot;
