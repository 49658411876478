import './css/App.css';
import './css/MobileUpload.css';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Article from '@mui/icons-material/Article';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import { API_URL } from './constants';
import { handleUpload } from './UploadUtil';

const MAX_FILE_SIZE = 10 * 1024 * 1024 * 1024; // 10GB in bytes

function UploadAndSearch({ currentUser }) {
    const isLoggedIn = Boolean(currentUser);
    const navigate = useNavigate();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [isUploadLoading, setIsUploadLoading] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState({
        query: "",
        answer: "",
        sourceDocument: []
    });
    const [pastSearchResults, setPastSearchResults] = useState([]);
    
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const [uploadedFilesSearch, setUploadedFilesSearch] = useState('');

    const handleSubscribe = () => {
        const userId = currentUser;  // Assuming currentUser holds the user ID
        const stripeUrl = `https://buy.stripe.com/00gbL9eBv828gOQ7ss?client_reference_id=${userId}`;
        window.location.href = stripeUrl;
    };

    const filteredUploadedFiles = uploadedFiles.filter(
        fileName => fileName.toLowerCase().includes(uploadedFilesSearch.toLowerCase())
      );

      const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            const files = Array.from(e.dataTransfer.items).map(item => item.getAsFile());
            files.forEach(file => {
                if (file.size > MAX_FILE_SIZE) {
                    setErrorMessage("Each file must be smaller than 10GB!");
                    return;
                }
                setSelectedFile(file);
                handleUpload(file, currentUser, setIsUploadLoading, setSelectedFile, fileInputRef, refreshFileList, setErrorMessage);
            });
        }
    };    

    const spotifyGreenTheme = createTheme({
        palette: {
            primary: {
                main: '#42d760', // spotify-green
            },
        },
    });

    const sortedUploadedFiles = uploadedFiles.sort((a, b) => a.localeCompare(b))

    useEffect(() => { 
        if (isLoggedIn) {
            document.body.style.overflow = 'hidden';
            return () => {
                document.body.style.overflow = 'auto'; 
            };
        }
    }, [isLoggedIn]);

    useEffect(() => {
        // If the user is not logged in, redirect to login page
        if (!isLoggedIn) {
            navigate('/login');
        }
    }, [currentUser, navigate]);

    useEffect(() => {
        if (currentUser) {
            fetch(`${API_URL}/get_uploaded_files_by_customerId?customerId=${currentUser}&folder=${currentUser}`)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        throw new Error(data.message);
                    });
                }
                return response.json();
            })
            .then(data => {
                if (data.fileNames && data.fileNames.length > 0) {
                    setUploadedFiles(data.fileNames);
                } else {
                    setErrorMessage();
                }
            })
            .catch(error => {
                setErrorMessage(error.message);
                console.error('Error fetching uploaded files:', error);
            });
        }
    }, [currentUser]);

    const refreshFileList = () => {
        fetch(`${API_URL}/get_uploaded_files_by_customerId?customerId=${currentUser}&folder=${currentUser}`)
        .then(response => {
            if (!response.ok) {
                return response.json().then(data => {
                    throw new Error(data.message);
                });
            }
            return response.json();
        })
        .then(data => {
            if (data.fileNames && data.fileNames.length > 0) {
                setUploadedFiles(data.fileNames);
            } else {
                setErrorMessage();
            }
        })
        .catch(error => {
            setErrorMessage(error.message);
            console.error('Error fetching uploaded files:', error);
        });
    }

    const deleteFile = (fileName) => {
        setIsDeleteLoading(true);
        fetch(`${API_URL}/delete_uploaded_file`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customerId: currentUser,
                folder: currentUser,
                fileName: fileName + '.pdf'  // Append .txt to the file name
            })
            })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        throw new Error(data.message);
                    });
                }
                return response.json();
            })
            .then(data => {
                console.log(data.message);
                // Remove the deleted file from the state
                setUploadedFiles(prevFiles => prevFiles.filter(file => file !== fileName));
            })
            .catch(error => {
                setErrorMessage(error.message);
                console.error('Error deleting file:', error);
            })
            .finally(() => {
                setIsDeleteLoading(false);
            });;
    }

    const handleSearch = () => {
        setIsSearching(true);
        if (!searchQuery) {
            setIsSearching(false); // Make sure to reset the searching state
            setErrorMessage("Please enter a search query.");
            return;
        }
    
        // Make the fetch request to your Flask API
        fetch(`${API_URL}/search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                customerId: currentUser,
                folder: currentUser,
                searchString: searchQuery
            })
        })
        .then(response => {
            if (response.status === 401) {
                setIsSearching(false); // Reset the searching state for 401 error
                setDialogOpen(true);   // Open the dialog for 401 error
                throw new Error("Not Authorized: You have exceeded the allowed searches as a free user."); // Stop the processing here
            }
            return response.json();
        })
        .then(data => {
            setIsSearching(false);
            if (data.answer) {
                let documents = data.sourceDocument.map(doc => {
                    // Extract the portion after the last `/` character
                    let fileName = doc.split('/').pop();
    
                    // Remove the chunkNum_ and replace `.txt` with `.pdf`
                    fileName = fileName.replace(/^\d+_/, '').replace('.txt', '.pdf');
    
                    return fileName;
                });
    
                // Filter out duplicates using Set
                documents = [...new Set(documents)];
    
                const newSearchResult = {
                    query: searchQuery,
                    answer: data.answer,
                    sourceDocument: documents
                };
                
                setPastSearchResults(prevResults => [newSearchResult, ...prevResults]);
            } else {
                setErrorMessage("No results found.");
            }
        })
        .catch(error => {
            if (!error.message.includes("Not Authorized")) {
                setIsSearching(false);
                setErrorMessage("Error searching documents: " + error.message);
            }
        });
    };    
    

    return (
        <div className="uploaded-section" 
     style={{ 
         display: 'flex', 
         maxWidth: '768px', 
         height: '80%', 
         paddingBottom: '0px',
         flexDirection: 'column',
     }}
        >
        <div className={isLoggedIn && !isDeleteLoading && !isUploadLoading ? 'upload-and-search-content' : 'upload-and-search-content blur-effect'}
        style={{flexDirection: 'column', backgroundColor: '#202123'}}>
            
                <Typography variant="h4" component="h2" style={{ fontWeight: 'bold', color: '#e0e0e0', padding: '20px'}}>
                    Sample Documents
                </Typography>

                {/* Add a TextField here for searching uploaded files */}
                <TextField 
                    hiddenLabel={false}  // Setting this to false to make the label visible
                    className="searchfiles-font-input"
                    placeholder="Search"
                    variant='filled'
                    fullWidth
                    width="100%"
                    height="10px"
                    value={uploadedFilesSearch} 
                    onChange={e => setUploadedFilesSearch(e.target.value)}
                />

                {errorMessage && <p style={{ color: 'white', fontSize: '18px', justifyContent: 'center' }}>{errorMessage}</p>}

                <div className="uploaded-files-section custom-scrollbar">
                    <ul className="uploaded-files-list">
                        {/* Use sortedUploadedFiles and then filter them */}
                        {sortedUploadedFiles.filter(fileName => fileName.toLowerCase().includes(uploadedFilesSearch.toLowerCase())).map(fileName => (
                            <li key={fileName} className="uploaded-file-item">
                                    <div className="file-info">
                                        <Article style={{ marginRight: '8px', color: '#e0e0e0' }} />
                                        <Typography variant="h6" component="span">
                                            {fileName.length > 21 ? `${fileName.substring(0, 21)}...` : fileName}
                                        </Typography>

                                    </div>
                                    {/* <IconButton
                                        className="deleteRoundedIcon hover-effect"
                                        onClick={() => deleteFile(fileName)}
                                    >
                                        <DeleteIcon className="default-icon" style={{ color: '#e0e0e0' }}/>
                                        <DeleteForeverRoundedIcon className="hover-icon"/>
                                    </IconButton> */}
                                </li>
                            ))}
                        </ul>
                        <div className="upload-section" style={{ marginTop: 'auto'}}>

                        <Button 
                            variant="outlined" 
                            color="success"
                            className="text-spotify-green-button"
                            component="label" // Make the button act as a label
                            style={{ color: '#42d760', borderRadius: '22px', padding: '18px', fontSize: '13px', height: '40px'}} // Spotify green
                            onClick={() => setUploadDialogOpen(true)}
                        >
                            Upload File
                        </Button>
                            
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Typography variant="caption" component="p" style={{ marginTop: '10px', fontSize: '13px'}}>
                                File Count: {uploadedFiles.length}
                            </Typography>
                            <Typography variant="caption" component="p" style={{ margin: '0', fontSize: '13px'}}>
                                Upload any PDF
                            </Typography>
                        </div>

                            {selectedFile && <span style={{ marginLeft: '10px', marginTop: '10px', fontSize: '20px' }}>{selectedFile.name}</span>}
                            <br />

                        </div>
                    </div>
                    

            </div>

            {isDeleteLoading && (

            <Backdrop open={true} style={{ zIndex: 1, color: '#fff' }}>
                <CircularProgress color = 'success' style={{ color: 'e0e0e0' }} />
            </Backdrop>

            )}

            {isUploadLoading && (
                <Dialog
                    open={true}
                    onClose={() => { /* Handle close if needed */ }}
                    PaperProps={{ className: "custom-dialog" }}
                >    
                    <div>
                        <DialogTitle>
                            <p>Training Docworm on your files.<br />
                            This can take a few minutes for large files.<br />
                            Do NOT refresh the page.
                            </p>
                        </DialogTitle>
                        <CircularProgress color="success" />
                    </div>
                </Dialog>                    
            )}

            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                PaperProps={{ className: "custom-dialog2" }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle 
                    id="alert-dialog-title" 
                    style={{ fontWeight: 'bold', fontSize: '24px' }}
                >
                    {"Subscription Required"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ color: 'white' }}>
                        Your account has exceeded the Free-Tier limit. 
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description" style={{ color: 'white' }}>
                        Please sign up to get unlimited searches.
                    </DialogContentText>
                    <p style={{ fontSize: '14px', color: 'white' }}>
                        <Link to="/subscription">
                            <Button variant="text" className="text-spotify-green-button2" style={{ fontSize: '14px'}}>
                            LEARN MORE
                            </Button>
                        </Link>
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} className="text-spotify-green-button1" autoFocus>
                        Dismiss
                    </Button>
                    <Button onClick={handleSubscribe} className="spotify-green-button" autoFocus style={{ marginLeft: '15px' }}>
                        Subscribe
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={uploadDialogOpen}
                onClose={() => setUploadDialogOpen(false)}
                PaperProps={{ className: "custom-dialog2" }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle 
                    id="alert-dialog-title" 
                    style={{ fontWeight: 'bold', fontSize: '24px' }}
                >
                    {"Upload Files"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ color: 'white' }}>
                    To upload your own files, please create an account.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button onClick={() => setUploadDialogOpen(false)} className="text-spotify-green-button1" autoFocus>
                        Dismiss
                        </Button>
                        <Button variant="contained" className="spotify-green-button" onClick={() => navigate('/signup')}  style={{ width: 'auto', height: '40px',borderRadius: '22px', padding: '18px', fontSize: '13px', marginLeft: '10px' }}>
                        Sign Up
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default UploadAndSearch;
